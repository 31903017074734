.nav {
  @apply flex items-center justify-between w-full mx-auto text-lg md:w-auto md:inline-flex;
}

.arrow {
  @apply text-center text-base flex items-center min-w-22 md:min-w-14;

  & :global(span) {
    @apply px-2 md:hidden;
  }

  & :global(.circle) {
    @apply flex items-center justify-center w-10 h-10 border border-black-25;
    @apply rounded-full cursor-pointer hover:bg-summer-green-20;
  }

  &:global(.dim) {
    @apply opacity-20 cursor-default;
  }

  &:global(.previous) {
    & :global(.circle) {
      @apply flip-horizontal;
    }
  }

  &:global(.next) {
    @apply flex-row-reverse;
  }
}

.number {
  @apply px-2.5 font-serif font-bold cursor-pointer;

  &:global(.is-current) {
    @apply flex items-center justify-center h-10 w-10 font-serif font-bold rounded-full bg-summer-green-50;
  }
}
